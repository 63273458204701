<script>
import api from '@/command/api'
import TemplateHeaderTable from '../../../components/TemplateHeaderTable'
import moment from 'moment'
import { regSourceList } from '@/utils/textFile'
import apiTool from '@/command/apiTool'
import { delAction, getAction, postAction, putAction } from '@/command/netTool'
import { message } from 'ant-design-vue'

export default {
  name: 'userManagement',
  data() {
    return {
      ...api.command.getState(),
      roleList: [],
      form: {},
    }
  },
  mounted() {
    this.getList()
    // getAction('/subRole/list').then((e) => {
    //   this.roleList = e.data.map((e) => ({ name: e.roleName, value: e.roleCode }))
    // })
  },
  methods: {
    getList({ current = 1, pageSize = 10 } = {}) {
      api.command.getList.call(this, {
        url: '/subUser/page',
        current: current,
        size: pageSize,
      })
    },
    getHeader() {
      return [
        {
          name: '用户',
          key: 'realName',
          type: 'input',
        },
        {
          name: '手机号',
          key: 'phone',
          type: 'input',
        },
      ]
    },
    getColumns() {
      return [
        {
          dataIndex: 'id',
          title: 'ID',
          align: 'left',
          sorter: (a, b) => a.id - b.id,
        },
        {
          dataIndex: 'realName',
          title: '用户名',
          align: 'left',
          customRender: (text, records) => {
            return (
              <div class="product-order-item-copy">
                {records.avatar ? <img src={records.avatar.split(',')[0]} /> : ''}
                <div>{text}</div>
              </div>
            )
          },
        },
        {
          dataIndex: 'phone',
          title: '手机号',
          align: 'left',
        },
        {
          dataIndex: 'roleNames',
          title: '权限',
          align: 'left',
        },
        {
          dataIndex: 'lockFlag',
          title: '状态',
          type: 'badge',
          filters: [
            {
              text: '冻结',
              value: '9',
            },
            {
              text: '正常',
              value: '0',
            },
          ],
          onExport: (records) => {
            return ['正常', '冻结'][records]
          },
          render(data) {
            return {
              showDot: true,
              name: data == '9' ? '冻结' : '正常',
              color: data == '9' ? 'red' : 'green',
            }
          },
          filterMultiple: false,
        },
        {
          dataIndex: 'createTime',
          title: '创建时间',
          align: 'left',
        },
        {
          dataIndex: 'a9',
          title: '操作',
          type: 'buttonGroup',
          typeData: ({ records }) => {
            return [
              {
                name: records.lockFlag == '0' ? '冻结' : '恢复账号',
                display: true,
                onClick: () => {
                  if (records.lockFlag == '9') {
                    putAction(`/subUser/enable?userId=${records.userId}`).then((e) => {
                      message.success('解冻成功')
                      this.getList()
                    })
                  } else {
                    putAction(`/subUser/disable?userId=${records.userId}`).then((e) => {
                      message.success('冻结成功')
                      this.getList()
                    })
                  }
                },
              },
              {
                name: '平台权限',
                display: true,
                onClick: () => {
                  this.form = { ...records, roleCodes: (records.roleCodes || '').split(',').filter((e) => e) }
                  this.onOpenPCModel(records)
                },
              },
              {
                name: 'H5商家权限',
                display: true,
                onClick: () => {
                  this.form = { ...records, roleCodes: (records.roleCodes || '').split(',').filter((e) => e) }
                  this.onOpenH5Model(records)
                },
              },
              {
                display: true,
                name: '编辑',
                onClick: () => {
                  this.form = { ...records }
                  this.onOpenModel(records)
                },
              },
              {
                display: true,
                name: '删除',
                popTitle: '确认是否删除吗?',
                type: 'pop',
                onClick: () => {
                  delAction(`/subUser/del?userId=${records.userId}`).then((e) => {
                    message.success('删除成功')
                    this.getList()
                  })
                },
              },
            ].filter((e) => e.display)
          },
        },
      ]
    },
    onOpenPCModel(records = {}) {
      getAction('/api/subUser/queryUserRoles', { userId: this.form.userId, sysCode: 'sznc' }).then((ea) => {
        let eaData = ea.data
        this.form = {
          ...records,
          roleCodes: (eaData.roleCodes || '').split(',').filter((e) => e),
        }
        getAction('/subRole/list', { sysCode: 'sznc' }).then((e) => {
          let roleList = e.data.map((e) => ({ name: e.roleName, value: e.roleCode }))
          apiTool.showDrawer({
            title: 'PC平台端',
            width: 600,
            formData: { ...this.form },
            layout: 'vertical',
            success: ({ data, setHidden }) => {
              let roleCodes = (data.roleCodes || []).join(',')
              putAction('/subUser/userRoles?sysCode=sznc', { ...data, roleCodes, sysCode: 'sznc' }).then((e) => {
                setHidden()
                this.getList()
              })
            },
            foot: ({ close, submit }) => {
              return [
                {
                  name: '取消',
                  onClick: close,
                },
                {
                  name: '完成',
                  type: 'primary',
                  onClick: submit,
                },
              ]
            },
            form: [
              {
                name: '角色分配',
                type: 'select',
                cols: 24,
                key: 'roleCodes',
                props: {
                  mode: 'multiple',
                },
                typeData: roleList,
                rules: [
                  {
                    required: true,
                    type: 'array',
                  },
                ],
              },
            ],
          })
        })
      })
    },
    onOpenH5Model(records = {}) {
      getAction('/subRole/list', { sysCode: 'H5Shop' }).then((e) => {
        let roleList = e.data.map((e) => ({ name: e.roleName, value: e.roleCode }))
        getAction('/api/farmTicketScenic/list').then((e) => {
          let farmTicketScenic = e.data.map((e) => ({ ...e, value: e.id }))
          getAction('/api/hotel/list').then((el) => {
            let hotel = el.data.map((e) => ({ ...e, value: e.id }))
            getAction('/api/subUser/queryUserRoles', { userId: this.form.userId, sysCode: 'H5Shop' }).then((ea) => {
              let eaData = ea.data
              this.form = {
                ...records,
                roleCodes: (eaData.roleCodes || '').split(',').filter((e) => e),
                scenicIds: (eaData.scenicIds || '').split(',').filter((e) => e),
                hotelIds: (eaData.hotelIds || '').split(',').filter((e) => e),
              }
              apiTool.showDrawer({
                title: 'h5商家端',
                width: 600,
                formData: { ...this.form },
                layout: 'vertical',
                success: ({ data, setHidden }) => {
                  let roleCodes = (data.roleCodes || []).join(',')
                  let scenicIds = (data.scenicIds || []).join(',')
                  let hotelIds = (data.hotelIds || []).join(',')
                  putAction('/subUser/userRoles?sysCode=H5Shop', {
                    ...data,
                    roleCodes,
                    scenicIds,
                    hotelIds,
                    sysCode: 'H5Shop',
                  }).then((e) => {
                    setHidden()
                    this.getList()
                  })
                },
                foot: ({ close, submit }) => {
                  return [
                    {
                      name: '取消',
                      onClick: close,
                    },
                    {
                      name: '完成',
                      type: 'primary',
                      onClick: submit,
                    },
                  ]
                },
                form: [
                  {
                    name: '核销景点',
                    type: 'select',
                    key: 'scenicIds',
                    cols: 12,
                    props: {
                      mode: 'multiple',
                    },
                    typeData: farmTicketScenic,
                  },
                  {
                    name: '核销酒店',
                    type: 'select',
                    key: 'hotelIds',
                    cols: 12,
                    props: {
                      mode: 'multiple',
                    },
                    typeData: hotel,
                  },
                  {
                    name: '角色分配',
                    type: 'select',
                    cols: 12,
                    key: 'roleCodes',
                    props: {
                      mode: 'multiple',
                    },
                    typeData: roleList,
                    rules: [
                      {
                        required: true,
                        type: 'array',
                      },
                    ],
                  },
                ],
              })
            })
          })
        })
      })
    },
    onOpenModel(records = {}) {
      apiTool.showDrawer({
        title: records.userId ? '编辑用户' : '新增用户',
        width: 600,
        formData: { ...this.form, roleCodes: (records.roleCodes || '').split(',') },
        layout: 'vertical',
        success: ({ data, setHidden }) => {
          if (records.userId) {
            putAction('/subUser', { ...data, roleCodes: data.roleCodes.toString() }).then((e) => {
              message.success('编辑成功')
              setHidden()
              this.getList()
            })
          } else {
            postAction('/subUser', { ...data, roleCodes: data.roleCodes.toString() }, '/api').then((e) => {
              message.success('新增成功')
              setHidden()
              this.getList()
            })
          }
        },
        foot: ({ close, submit }) => {
          return [
            {
              name: '取消',
              onClick: close,
            },
            {
              name: '完成',
              type: 'primary',
              onClick: submit,
            },
          ]
        },
        form: [
          {
            name: '头像',
            type: 'upload',
            key: 'avatar',
            cols: 24,
            rules: [
              {
                required: true,
                message: '请选择图片',
              },
            ],
          },
          {
            name: '用户名',
            type: 'input',
            cols: 12,
            key: 'realName',
            // labelCol: { span: 16 },
            // wrapperCol: { span: 8 },
            rules: [
              {
                required: true,
              },
            ],
          },
          {
            name: '手机号',
            type: 'input',
            cols: 12,
            key: 'phone',
            rules: [
              {
                required: true,
                ruleType: 'phone',
                message: '请输入正确手机号',
              },
            ],
          },
          {
            name: '状态',
            type: 'select',
            cols: 12,
            placeholder: '请选择状态',
            key: 'lockFlag',
            // onChange: (value) => {
            //   this.form['lockFlag'] = value
            // },
            typeData: [
              {
                name: '正常',
                value: '0',
              },
              {
                name: '冻结',
                value: '9',
              },
            ],
            rules: [
              {
                required: true,
              },
            ],
          },
        ],
      })
    },
    getButton() {
      return [
        {
          name: '添加',
          onClick: () => {
            this.form = {}
            this.onOpenModel()
          },
        },
      ]
    },
  },
  render() {
    return (
      <TemplateHeaderTable
        headerData={this.getHeader()}
        tableColumns={this.getColumns()}
        button={this.getButton()}
        records={this.records}
        onChange={(data) => this.getList(data)}
      />
    )
  },
}
</script>
